<template>
  <div class="multiple-patrol-list default-table-view scrollbar" v-loading="loading">
    <div class="main-hd">
      <el-form @keyup.enter.native="queryList()">
        <el-input v-model="dataForm.siteName" placeholder="工地名称"></el-input>
        <el-input v-model="dataForm.baseName" placeholder="所属基地"></el-input>
        <el-button @click="queryList()" type="primary">查询</el-button>
      </el-form>
    </div>
    <el-table size="mini" :data="tableData" border stripe style="width: 100%;">
      <el-table-column type="index" header-align="center" align="center" label="序号" width="50" ></el-table-column>
      <el-table-column prop="createTime" label="巡查日期" width="110"></el-table-column>
      <el-table-column prop="patrolUserName" label="巡查者" width="180" show-overflow-tooltip></el-table-column>
      <el-table-column label="巡查类型" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.patrolType === 1  ? '人工巡查' : '线上巡查' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="巡查结果是否一致" width="150">
        <template slot-scope="scope">
          <span :class="{'red': scope.row.isDifferent}">{{ scope.row.isDifferent ? '否' : '是' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="施工车辆" width="100"></el-table-column>
      <el-table-column prop="carNickName" label="车长" width="100"></el-table-column>
      <el-table-column prop="siteName" label="工地名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="siteAddress" label="工地地址" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-tooltip content="查看详情" placement="left">
            <el-button
              circle
              size="mini"
              type="infor"
              icon="el-icon-view"
              @click="view(scope.row)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-pagination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>

    <el-drawer
      title="巡查详情"
      append-to-body
      size="900px"
      custom-class="multiple-patrol-detail-drawer"
      :visible.sync="showDetail">
      <div>
        <div class="hd-info">
          <div>
            <div class="item"><span class="label">工地名称:</span><span class="content">{{ currentRow.siteName }}</span></div>
            <div class="item"><span class="label">施工部位:</span><span class="content">{{ currentRow.constructionPart }}</span></div>
          </div>
          <div>
            <div class="item" style="width: 100%;"><span class="label">工地地址:</span><span class="content">{{ currentRow.siteAddress }}</span></div>
          </div>
          <div>
            <div class="item"><span class="label">施工车辆:</span><span class="content">{{ currentRow.number }}</span></div>
            <div class="item"><span class="label">车长:</span><span class="content">{{ currentRow.carNickName }}</span></div>
            <div class="item"><span class="label">泵别:</span><span class="content">{{ currentRow.carSize }}米</span></div>
          </div>
        </div>
        <el-table :data="drawerTableData" border stripe style="width: 100%;" height="calc(100vh - 170px)">
          <el-table-column prop="dictName" label="巡查项目" width="180" fixed="left"></el-table-column>
          <el-table-column
            v-for="(item, index) in drawerTableColumn"
            :key="index"
            :prop="item"
            :label="item">
            <template slot-scope="scope">
              <span :class="{'red': scope.row[scope.column.property] != 1}">{{ scope.row[scope.column.property] == 1 ? '通过' : '不通过' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'dispatch-patrol',

  components: {},

  data () {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      dataForm: {
        baseName: '',
        siteName: ''
      },
      loading: false,
      showDetail: false,
      currentRow: {},
      drawerTableData: [],
      drawerTableColumn: []
    }
  },

  methods: {
    queryList () {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl('/dispatch/taskPatrol/querydiff'),
        method: 'post',
        data: this.$http.adornData({ ...this.dataForm, page: this.page, pageSize: this.pageSize })
      }).then(data => {
        this.tableData = _.get(data, 'datas.list', [])
        this.total = _.get(data, 'datas.total', [])
      }).finally(() => {
        this.loading = false
      })
    },

    // 每页数
    handleSizeChange (val) {
      this.pageSize = val
      this.page = 1
      this.queryList()
    },

    // 当前页
    handleCurrentChange (val) {
      this.page = val
      this.queryList()
    },

    view (row) {
      // console.log(JSON.parse(JSON.stringify(row)))
      this.currentRow = _.cloneDeep(row)
      let contentMap = this.currentRow.contentMap || {}
      this.drawerTableColumn = Object.keys(contentMap)
      if (this.drawerTableColumn.length > 0) {
        let arr = _.map(contentMap[this.drawerTableColumn[0]], item => {
          let obj = {
            dictName: item.dictName,
            dictId: item.dictId
          }
          obj[this.drawerTableColumn[0]] = item.patrolStatus
          return obj
        })
        this.drawerTableColumn.map((item, index) => {
          if (index > 0) {
            contentMap[item].map((el, i) => {
              arr[i][this.drawerTableColumn[index]] = el.patrolStatus
            })
          }
        })
        // console.log(arr)
        this.drawerTableData = arr
      } else {
        this.drawerTableData = []
      }
      console.log(this.drawerTableData)
      this.showDetail = true
    }
  },

  created () {
    this.queryList()
  }
}
</script>

<style lang="scss">
.multiple-patrol-list {
  .red {
    color: red;
  }
  .main-hd {
    margin-bottom: 15px;
    .el-form {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      & > .el-input,
      & > .el-select {
        width: 150px;
        margin-right: 10px;
        margin-bottom: 15px;
      }
      & > .el-button {
        margin-right: 10px;
        margin-bottom: 15px;
      }
    }
  }
}
.multiple-patrol-detail-drawer {
  .red {
    color: red;
  }
  .hd-info {
    font-size: 13px;
    margin-bottom: 20px;
    >div {
      line-height: 1;
      margin-top: 16px;
      .item {
        width: 33.33%;
        display: inline-block;
        .label {
          margin-right: 8px;
          margin-left: 100px;
          font-weight: bold;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
